.page-content h3 {
  margin-bottom: 1.5rem;
}
.page-content p {
  text-align: justify;
  color: #444;
}
.bd-ul-none ul{
  list-style: none;
  padding-left: 0;
}
.cursor-pointer{
  cursor: pointer;
}