#section-table {}

#home-section-2 {
  background: url("/src/assets/Images/bg-img.jpg");
  border-radius: 0 30px;
}

#home-section-2 p {
  font-size: 13px;
}

.container-box {
  padding-left: 5%;
  padding-right: 5%;
}

.min-width {
  max-width: 1100px;
}

.logo {
  height: 100px;
  transform: translateY(10px);
}

.top-contact {
  position: absolute;
  width: 60%;
  top: 0;
  right: 0;
  z-index: 9999;
  padding-right: 60px;
  border-radius: 50px 0 0 50px;
}

.pd-navbar {
  /* background-color: #edc6ffd1; */
  background-color: #fff;
  position: relative;
  height: 120px;
}

.skicky-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  animation: fadedownNav 0.8s ease-in-out 0s 1 normal none running;
  box-shadow: 2px 2px 10px #33333360;
  z-index: 999;
}
.absolute-navbar {
  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  animation: fadedownNav 0.8s ease-in-out 0s 1 normal none running;
  box-shadow: 2px 2px 10px #33333360;
  z-index: 999;
}

@keyframes fadedownNav {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.navlinks {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
  position: relative;
}

.navlinks>li>a:hover {
  /* background-color: var(--orange-color); */
  color: #ed3535 !important;
}

.navlinks li a,
.navlinks li button {
  color: #222;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 15px;
  display: block;
  padding: 14px 22px;
}

/* #dropdown-toggle:hover #dropdown-toggle .dropdown-menu .show {
  display: block !important;
} */
#dropdown-toggle:hover+.dropdown-menu {
  display: block;
}

.dropdown {
  list-style: none;
  padding: 0;
}

.dropdown-item {
  position: relative;
  display: inline-block;
  text-align: center;
}

.dropdown-item .submenu {
  display: none;
  position: absolute;
  top: 101%;
  left: 0%;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 0;
  z-index: 10;
}

.dropdown-item .submenu a:hover {
  color: #ed3535 !important;
}

.dropdown-item:hover .submenu {
  display: block;
}

.submenu li {
  display: block;
}

/* ....................................................................... */

.dropdown-menu {
  display: none;
}

/* page Header */
.page-header {
  /* background-image: linear-gradient(#ffffff56, #00000091 60%), url('https://images.unsplash.com/photo-1677442136019-21780ecad995?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'); */
  background-size: cover;
  background-position: 40% 40%;
  background-attachment: fixed;
}

.slider-image {
  height: 320px;
  object-fit: cover;
  object-position: bottom;
}

/* steps */
.steps-box {
  position: relative;
  z-index: 10;
  min-width: 40%;
}

.steps-box div {
  position: relative;
  z-index: 10;
  background-color: #fff;
}

.steps-box .step-line {
  position: absolute;
  top: 47.5%;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #f2f2f2;
  z-index: 9;
}

.button {
  padding: 12px 27px;
  background-color: #437812;
  color: #f2f2f2;
  border: none;
  font-size: large;
  border-radius: 5px;
}

label {
  font-size: 17px;
  margin-bottom: 6px;
}

.form-field {
  width: 100%;
  padding: 10px 18px;
  border-radius: 5px;
  border: none;
}
.form-select{
  width: 100%;
  padding: 10px 18px;
  border-radius: 5px;
  border: none;
}

.form-field:focus {
  outline: 2px solid #999 !important;
  background-color: #daeaf9;
}

.table thead th {
  font-size: 14px;
  vertical-align: middle;
  font-weight: 600;
}

.table tbody td {
  height: 65px;
}

.table thead th {
  background-color: #145994 !important;
  color: #fff;
}

.bg-contact {
  background-image: linear-gradient(#222222a5, #222222a5),
    url('../Images/Slider/tathastu.jpg');
  background-size: cover;
  background-attachment: fixed;
}

.animated-text {
  animation: MoveUpDown 2s linear infinite;
}

@keyframes MoveUpDown {

  0%,
  50% {
    background-color: #ff3c41;
    box-shadow: 1px 1px 10px #222;
  }

  100% {
    background-color: #135a94;
  }
}

.open-status {
  animation: OpenStatus 2s linear infinite;
}

@keyframes OpenStatus {

  0%,
  50% {
    background-color: #437812;
    box-shadow: 1px 1px 10px #222;
  }

  100% {
    background-color: #135a94;
  }
}

.text-animated {
  animation: textAnimated 1s linear infinite;
  /* padding: 0 10px; */
  color: #fff;
}

@keyframes textAnimated {
  0%,
  50% {
    /* color: #ed3535; */
    color: #ff3c41;
  }

  100% {
    color: #437812;
    /* color: #fff; */
  }
}

.bg-cta {
  background-image: linear-gradient(#222222b5, #222222b5),
    url("../Images/Slider/cta_image.jpg");
  background-position: 30% 30%;
}

@media screen and (min-width: 1700px) {
  .customer-container {
    max-width: 85vw;
  }

  p {
    font-size: 20px !important;
  }

  ul li {
    font-size: 20px !important;
  }
}

.menu-btn {
  display: none;
  padding: 7px;
  line-height: 0px;
  border-radius: 50px;
}

.map {
  width: 100%;
  height: 80vh;
}

@media screen and (max-width: 1038px) {

  .top-contact {
    position: absolute;
    width: 80%;
  }

  .navlinks li a {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 850px) {
  .top-contact {
    position: absolute;
    width: 80%;
  }


  .navlinks {
    position: absolute;
    background-color: #135a94;
    height: auto;
    width: 230px;
    z-index: 10;
    flex-direction: column;
    top: 100%;
    align-items: flex-start;
    justify-content: space-evenly;
    right: 5%;
    display: none;
  }

  .navlinks li a {
    color: #fff;
    background-color: transparent !important;
  }

  .menu-btn {
    display: inline;
  }

}

@media screen and (max-width: 1208px) {
  .cta-btns{
    flex-direction: column  ;
  }
  .cta-btns > div{
    margin-top: 30px;
  }
}


/* mobile responsive */
@media screen and (max-width: 768px) {
  .main-banner {
    max-height: 70vh !important;
    min-height: auto;
  }

  .top-contact {
    display: none;
  }

  .inquiry-form {
    transform: translateY(10%) translateX(0px);
  }

  .list-count p {
    padding-right: 10px;
    font-size: 20px;
  }

  .l-section {
    padding-top: 100px;
  }

  .call-text {
    font-size: 22px;
  }

  .slider-image {
    height: 50vh;
  }

  .menu-btn:active {
    background-color: #135a941d;
  }

  .show-menu {
    display: block;
  }

  .map {
    height: 450px;
  }

  .table {
    width: 1600px;
  }

  /* Navbar Dropdown */
  .navlinks li button {
    color: white !important;
  }

  .dropdown-item .submenu {
    display: none;
    position: relative;
    background-color: #5089b78c;
    border: none;
    padding: 0;
    width: 100%;
  }

  /* .item-icon {
    position: absolute;
    width: 50px;
    height: 100%;
    background-color: transparent;
    padding-top: 15px;
    right: 3px;
    top: 0;
  } */
  .dropdown-item > a {
    text-align: right;
  }
  .submenu li a{
    font-size: 14px;
  }
}

@media screen and (max-width: 410px) {
  .top-contact div a {
    font-size: 14px !important;
  }

  .dropdown-item .submenu {
    left: 0;
  }

  .dropdown-item .submenu a {
    font-size: 14px !important;
    width: 100%;
  }
}

.hover-effect:hover {
  transform: scale(1.05) !important;
  background-image: linear-gradient(#fff 25%, #45761056);

}

.review-box {
  min-height: 290px;
}

.review-box i {
  font-size: 20px;
  color: #4b9fdd;
}

.marquee-container {
  height: 170px;
  overflow: hidden;
  position: relative;
}

.marquee {
  position: absolute;
  width: 100%;
  animation: marquee 10s linear infinite;
}

.marquee:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    top: 0;
  }

  100% {
    top: -100%;
  }
}

.title-marquee {
  display: flex;
  align-items: center;
}

.title-marquee i {
  font-size: 1.2rem;
}

.title-marquee h3 {
  margin-bottom: 0;
  font-weight: bold;
  color: #333;
}

.line-marquee {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.paragraph-marquee {
  font-size: 14px;
  line-height: 1.5;
}

.title-marquee .heading-marquee {
  margin: 0;
  color: #333;
}

@media (min-width: 1400px) {
  .title-marquee .heading-marquee {
    font-size: 1.4rem;
  }
}

@media (max-width: 1399px) {
  .title-marquee .heading-marquee {
    font-size: 1.15rem;
  }
}

/* new home design */
.listbox-heading {
  font-size: 14px;
  letter-spacing: 1px;
}

.listbox-content {
  border-bottom: 1px dotted #999;
}

.listbox-content span {
  font-size: 12px;
  display: block;
}

.listbox-content span a:hover {
  color: #135a94 !important;
  font-weight: 500;
}

.listbox-content p {
  font-size: 14px;
  font-weight: 600;
}
.table-responsive{
  scrollbar-width: none;
}
.project_details table tr td{
  border: 1px solid #a8bcd3;
  padding: 15px;
  height: auto;
}

.project_details table tr td:nth-child(1){
  width: 15%;
  background-color: #dcecf3;
  font-size: 15px;
  color: #135a94 !important;
  font-weight: bold;
}

.project_details table tr td:nth-child(2){
  width: 30%;
}

.project_details table tr td:nth-child(3){
  width: 15%;
  background-color: #dcecf3;
  font-size: 15px;
  color: #135a94 !important;
  font-weight: bold;
}

.project_details table tr td:nth-child(4){
  width: 30%;
}

.project_details table tr td > p{
  margin: 0 !important;
  font-size: 15px;
  padding: 5px 0;
  /* border-bottom: 1px dotted rgb(181, 176, 176); */
}