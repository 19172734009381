@import url('https://fonts.googleapis.com/css2?family=Inder&family=Mukta+Malar:wght@200;300;400;500;600;700;800&display=swap');

*{
    /* font-family: "Mukta Malar", sans-serif; */
}

.text-red {
    color: #ec1c24 !important;
}

.bg-red {
    background-color: #ec1c24 !important;
}

.bg-blue {
    background-color: #135a94 !important;
}

.bg-green {
    background-color: #427611;
}
.bg-dark-opacity{
    background-color: #0000006d;
    box-shadow: 1px 1px 20px #111111bd inset;
    border-radius: 15px;
}

.text-blue {
    color: #135a94;
}

.text-green {
    color: #427611;
}

.bg-light-blue {
    background-color: #135a941b !important;
}

.line {
    width: 100px;
    height: 3px;
    background-color: #fe1e258f;
}

.text-orange {
    color: #e76223;
}

.paragraph {
    color: #444;
}

.bg-dark-transparent {
    background-color: #0000007e;
}

.btn-width {
    width: 120px;
}

/* sector-tabs */
.sector-tabs .nav {
    justify-content: center;
    background-color: #135a941b;
    padding-top: 40px;
    border-bottom: 0;
}

.sector-tabs .nav-item{
    background-color: #fff;
    /* box-shadow: 1px 1px 16px #0000000e; */
}

.sector-tabs .nav-item .nav-link {
    width: 230px ;
    border-radius: 0;
    font-weight: 600;
    padding: 12px 20px;
    color: #222;
    border: 0;
    border-bottom: 3px solid #fff;
    text-transform: uppercase;
}

.sector-tabs .nav-item .nav-link:hover {
    outline: none;
}

.sector-tabs .nav-item .nav-link.active {
    border-bottom: 3px solid #447610;
    background-image: linear-gradient(#fff 25%, #45761018);
    color: #447610;
}



@media (max-width: 600px) {
    .sector-tabs .nav-item .nav-link {
        width: 220px;
        padding: 10px;
    }
}

.qr-image{
    height: 300px;
    object-fit: cover;
    border-radius: 15px;
}